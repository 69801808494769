import React from "react"
import { Link } from "gatsby"
import { Container, Box, Chip, Stack } from "@mui/material"
import { Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
const cur = process.env.GATSBY_CURRENCY

const CategoryProductGrid = ({ products }) => {
  const getPrice = (price) =>
    Intl.NumberFormat(undefined, {
      currency: cur,
      minimumFractionDigits: 0,
      style: "decimal",
    }).format(parseFloat(price ? price : 0))
  return (
    <Container
      maxWidth="xl"
      className="product-grid"
      sx={{
        mt: {
          lg: 0,
          xs: 5,
        },
      }}
    >
      <Row>
        {products && products.length > 0 ? (
          products.map(
            (
              {
                id,
                totalInventory,
                slug,
                tags,
                title,
                media: [firstImage, secondImage],
                variants: [variant],
                priceRangeV2: {
                  minVariantPrice: { amount },
                },
              },
              index
            ) => (
              <Col xl={3} lg={4} md={6} sm={6} xs={6} key={id}>
                <Box
                  component={Link}
                  to={slug}
                  marginBottom={{ md: 10, xs: 5 }}
                  position="relative"
                  className="d-flex flex-column align-items-center p-1"
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    position={{ md: "absolute", xs: "block" }}
                    height={{
                      md: 24,
                      xs:
                        index % 2 === 0
                          ? products[index].tags?.length ||
                            products[index + 1]?.tags?.length
                            ? 24
                            : 0
                          : products[index].tags?.length ||
                            products[index - 1]?.tags?.length
                          ? 24
                          : 0,
                    }}
                    zIndex={1000}
                  >
                    {tags?.map((tag, index) => (
                      <Chip
                        label={tag}
                        size="small"
                        key={index}
                        sx={{
                          fontSize: 16,
                          color: tag === "New" ? "black" : "white",
                          backgroundColor: tag === "New" ? "#acf736" : "red",
                          borderRadius: 1,
                          padding: 0,
                        }}
                      />
                    ))}
                  </Stack>
                  <div className="shop-pic-wrapper">
                    <Box position="relative">
                      {!totalInventory ? (
                        <>
                          <div className="shop-pic-soldout">
                            <div className="shop-pic-soldout-text">
                              SOLD OUT
                            </div>
                          </div>
                        </>
                      ) : null}
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            md: "block",
                          },
                        }}
                        className={`shop-pic-upper`}
                      >
                        <GatsbyImage
                          alt={firstImage?.image?.altText ?? title}
                          image={firstImage?.image?.gatsbyImageData}
                          loading={"eager"}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            md: "none",
                          },
                        }}
                        className={`shop-pic-upper-nohover`}
                      >
                        <GatsbyImage
                          alt={firstImage?.image?.altText ?? title}
                          image={firstImage?.image?.gatsbyImageData}
                          loading={"eager"}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            md: "block",
                          },
                        }}
                        className={`shop-pic-lower${
                          totalInventory ? "" : " saturate"
                        }`}
                      >
                        <GatsbyImage
                          alt={secondImage?.image?.altText ?? title}
                          image={
                            secondImage?.image?.gatsbyImageData ||
                            firstImage?.image?.gatsbyImageData
                          }
                          loading={"eager"}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className={"shop-pic-price-wrapper"}>
                    <div className="shop-pic-name">{title}</div>
                    {!!variant?.compareAtPrice &&
                      variant.compareAtPrice !== "0.00" && (
                        <div className="shop-pic-price-old">
                          <strike>
                            {getPrice(variant.compareAtPrice) + ` ${cur}`}
                          </strike>
                        </div>
                      )}
                    <div className="shop-pic-price">
                      {amount !== "0.00"
                        ? getPrice(amount) + ` ${cur}`
                        : "OUT OF STOCK"}
                    </div>
                  </div>
                </Box>
              </Col>
            )
          )
        ) : (
          <p>No Products found!</p>
        )}
      </Row>
    </Container>
  )
}

export default CategoryProductGrid
