import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { Seo } from "../../../components/seo"
import { Box } from "@mui/material"
import Slider from "react-slick"
import { Interweave } from "interweave"
import Magnifier from "react-magnifier"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"
import ProductForm from "../../../components/ProductForm"
import SizeChart from "../../../components/size-chart"
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../../../components/CustomArrows"
import { useElementSize } from "usehooks-ts"

const cur = process.env.GATSBY_CURRENCY
let dragging = false
export default function Product({ data: { product, suggestions } }) {
  const {
    variants: [initialVariant],
    title,
    description,
    media,
    media: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)
  const [isRendered, setIsRendered] = React.useState(false)
  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const [zoomed, setZoomed] = React.useState(false)
  const [more, setMore] = React.useState(false)
  const [size, setSize] = React.useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState(0)
  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const sizeChart =
    product.options.find((item) => item.name === "Size Chart") || null
  const sizeChartType = sizeChart ? sizeChart.values[0] : null
  const [descriptionRef, { width, height }] = useElementSize()

  const { innerHeight } =
    typeof window !== "undefined" ? window : { innerHeight: 0 }
  const cardHeight = innerHeight ? innerHeight - (44 + 198 + 117 + 50 + 11) : 0
  const contentHeight = innerHeight ? innerHeight - (44 + 198 + 117) : 0

  const handleZoom = (index) => {
    setSelectedProduct(index)
    setZoomed(true)
  }

  const selectPrev = () => {
    if (selectedProduct === 0) {
      setSelectedProduct(media.length - 1)
    } else setSelectedProduct((num) => num - 1)
  }

  const selectNext = () => {
    if (selectedProduct === media.length - 1) {
      setSelectedProduct(0)
    } else setSelectedProduct((num) => num + 1)
  }
  const getPrice = (price) =>
    Intl.NumberFormat(undefined, {
      currency: cur,
      minimumFractionDigits: 0,
      style: "decimal",
    }).format(parseFloat(price ? price : 0))

  const filteredSuggestions =
    suggestions?.nodes?.length > 0
      ? suggestions.nodes.filter(({ collections }) =>
          collections.every(({ handle }) => handle !== "store-inventory")
        )
      : []

  const customPaging = (i) => {
    if (!media || !media[i]) return null
    return (
      <a>
        <GatsbyImage
          objectFit="contain"
          loading="eager"
          image={media[i].image.gatsbyImageData}
          alt={title}
        />
      </a>
    )
  }

  React.useEffect(() => {
    setIsRendered(true)
  }, [])
  if (!isRendered) {
    return (
      <Layout>
        <h3>Loading...</h3>
      </Layout>
    )
  }
  return (
    <Layout>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage.image.gatsbyImageData)}
        />
      ) : undefined}
      <Box
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
          zIndex: 100,
          top: 0,
          left: 0,
          cursor: "zoom-out",
          display: zoomed ? "block" : "none",
          backgroundColor: "white",
        }}
      >
        <Box
          position="fixed"
          onClick={() => setZoomed(false)}
          padding={"15px"}
          top={0}
          right={0}
          cursor="pointer"
          zIndex={200}
        >
          <FontAwesomeIcon icon={faTimesCircle} size="3x" color="gray" />
        </Box>
        <Box height="100%" width="100%" display="flex">
          <Box flexGrow={1} className="left-cursor" onClick={selectPrev}></Box>
          <Magnifier
            height={"100%"}
            mgShape="square"
            onClick={() => setZoomed(false)}
            mgWidth={250}
            mgHeight={250}
            width="auto"
            className="magnifier-wrapper"
            src={media[selectedProduct].image.originalSrc}
          />
          <Box flexGrow={1} className="right-cursor" onClick={selectNext} />
        </Box>
      </Box>
      <Box>
        <Box
          marginBottom={5}
          px={{ xs: 3, lg: 5, xl: 10 }}
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          // className="d-flex flex-lg-row flex-column"
        >
          <Box
            display={{ xs: "none", lg: "block" }}
            flexBasis={"20%"}
            position="relative"
            height={{ xs: "unset", lg: more ? "unset" : contentHeight }}
            overflow={{ xs: "visble", lg: more ? "visible" : "hidden" }}
          >
            <Box
              paddingX={{ xs: 3, lg: 0 }}
              mt={0}
              className="product-info-box"
              style={{
                width: "100%",
                maxWidth: "600px",
              }}
            >
              <div className="heading-3">{product.title}</div>
              {!!variant?.compareAtPrice && (
                <div className="shop-pic-price-old">
                  <strike>
                    {getPrice(variant.compareAtPrice) + ` ${cur}`}
                  </strike>
                </div>
              )}
              <p>
                {product.variants[0].price !== "0.00"
                  ? getPrice(product.variants[0].price) + ` ${cur}`
                  : "OUT OF STOCK"}
              </p>

              <Box ref={descriptionRef}>
                <Interweave content={product.descriptionHtml} />
              </Box>
              <br />
            </Box>
            {height > contentHeight && (
              <Box
                position="absolute"
                bottom={0}
                pt={3}
                width="100%"
                sx={{
                  background: more
                    ? "unset"
                    : "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.929030987394958) 67%, rgba(255,255,255,0) 100%)",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <button
                    className="add-to-cart"
                    style={{ width: "unset", padding: "0 10px" }}
                    onClick={() => setMore(!more)}
                  >
                    READ {more ? "LESS" : "MORE"}
                  </button>
                </div>
              </Box>
            )}
          </Box>
          <Box flex={1}>
            <Box
              maxWidth={{ xs: "unset", lg: cardHeight + 55 * 2 }}
              sx={{
                width: "100%",
                display: "flex",
                margin: "0 auto",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Box width="100%">
                <Slider {...productSettings} customPaging={customPaging}>
                  {media.map((image, index) => (
                    <React.Fragment key={`image_${index}`}>
                      <div className="d-md-block d-none">
                        <Box
                          maxWidth={`80%`}
                          onClick={() => !dragging && handleZoom(index)}
                          height="auto"
                          marginBottom={3}
                          flexShrink={0}
                          display="flex"
                          justifyContent="center"
                          flexGrow={0}
                          marginX="auto"
                          style={{ cursor: "zoom-in" }}
                          // marginTop={3}
                        >
                          <GatsbyImage
                            objectFit="contain"
                            loading="eager"
                            image={image.image.gatsbyImageData}
                            alt={title}
                          />
                        </Box>
                      </div>
                      <div className="d-block d-md-none">
                        <Box
                        // marginTop={3}
                        >
                          <GatsbyImage
                            objectFit="contain"
                            loading="eager"
                            image={image.image.gatsbyImageData}
                            alt={title}
                          />
                        </Box>
                      </div>
                    </React.Fragment>
                  ))}
                </Slider>
              </Box>
            </Box>
          </Box>
          <Box display={{ xs: "block", lg: "none" }} flexBasis={"20%"}>
            <Box
              paddingX={{ xs: 3, lg: 0 }}
              mt={{ xs: "90px", lg: 0 }}
              className="product-info-box"
              style={{
                width: "100%",
                maxWidth: "600px",
              }}
            >
              <div className="heading-3">{product.title}</div>
              {!!variant?.compareAtPrice && (
                <div className="shop-pic-price-old">
                  <strike>
                    {getPrice(variant.compareAtPrice) + ` ${cur}`}
                  </strike>
                </div>
              )}
              <p>
                {product.variants[0].price !== "0.00"
                  ? getPrice(product.variants[0].price) + ` ${cur}`
                  : "OUT OF STOCK"}
              </p>
              <Interweave content={product.descriptionHtml} />
              <br />
            </Box>
          </Box>
          <Box flexBasis={"20%"}>
            <Box
              maxWidth={{ xs: "100%", lg: 200 }}
              ml={{ xs: 0, lg: "auto" }}
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <div>SIZE</div>
              <ProductForm
                product={product}
                variantId={productVariant.storefrontId}
                setVariant={setVariant}
                quantity={quantity}
              />
              {sizeChartType ? (
                <SizeChart size={size} setSize={setSize} type={sizeChartType} />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }, productType: { ne: "Lookbook" }) {
      title
      id
      description
      descriptionHtml
      productType
      handle
      productTypeSlug: gatsbyPath(
        filePath: "/shop/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      media {
        ... on ShopifyMediaImage {
          id
          image {
            altText
            originalSrc
            gatsbyImageData(layout: CONSTRAINED, width: 1600, aspectRatio: 1)
          }
        }
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        compareAtPrice
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        shopifyId
      }
    }
    suggestions: allShopifyProduct(
      sort: { order: DESC, fields: [createdAt] }
      filter: { productType: { eq: $productType }, id: { ne: $id } }
      limit: 5
    ) {
      nodes {
        ...ProductCard
        collections {
          handle
        }
      }
    }
  }
`
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  touchThreshold: 100,
  swipeToSlide: true,
  initialSlide: 0,
  beforeChange: () => (dragging = true),
  afterChange: () => (dragging = false),
  nextArrow: <CustomRightArrow />,
  prevArrow: <CustomLeftArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const productSettings = {
  dots: true,
  // infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  beforeChange: () => (dragging = true),
  afterChange: () => (dragging = false),
  // nextArrow: <CustomRightArrow />,
  // prevArrow: <CustomLeftArrow />,
}
